import { createSlice } from "@reduxjs/toolkit";
import { rates } from "../../../helpers/constants/reduxConstants";

const initialState = {
  data: [],
  isLoading: true,
  message: "",
};

const ratesTopFilter = createSlice({
  name: rates,
  initialState,
  reducers: {
    loadRatesRefreshed(state){
      state.isLoading = false;
    },
    loadRatesTopFilterLookup(state){
      state.data = [];
      state.isLoading = false;
      state.message = ""
    },
    loadRatesTopFilter(state) {
      state.isLoading = true;
    },
    loadRatesTopFilterSuccess(state, action) {
      state.data = action.data.data;
      state.statusCode = action.data && action.data.status ? action.data.status : "";
      state.isLoading = false;
    },
    loadRatesTopFilterFailed(state, action) {
      state.message = "loading rates filters failed";
      state.statusCode = action.status ? action.status : "";
      state.isLoading = false;
      state.data=[]
    },
  },
});
export const { loadRatesTopFilter, loadRatesTopFilterSuccess, loadRatesTopFilterFailed, loadRatesTopFilterLookup,loadRatesRefreshed } = ratesTopFilter.actions;
export default ratesTopFilter.reducer;
