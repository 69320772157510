import { createSlice } from "@reduxjs/toolkit";
import { reviews } from "../../../helpers/constants/reduxConstants";

const initialState = {
  data: [],
  isLoading: true,
  message: "",
  reviewData: [],
  sources:[]
};

const getReviews = createSlice({
  name: reviews,
  initialState,
  reducers: {
    loadReviews(state) {
      state.isLoading = true;
    },
    loadReviewsSuccess(state, action) {
      state.data = action.data;
      state.isLoading = false;
    },
    loadReviewsFailed(state) {
      state.message = "loading reviews failed";
      state.isLoading = false;
    },
    loadHotelReviews(state) {
      state.isLoading = true;
    },
    loadHotelReviewsSuccess(state, action) {
      state.reviewData = action.data;
      state.isLoading = false;
    },
    loadHotelReviewsFailed(state) {
      state.message = "loading reviews failed";
      state.isLoading = false;
    },
    loadSources(state) {
      state.isLoading = true;
    },
    loadSourcesSuccess(state, action) {
      state.sources = action.data;
      state.isLoading = false;
    },
    loadSourcesFailed(state) {
      state.message = "loading reviews failed";
      state.isLoading = false;
    },
  },
});
export const { loadReviews, loadReviewsSuccess, loadReviewsFailed,
  loadHotelReviews, loadHotelReviewsSuccess, loadHotelReviewsFailed,
  loadSources, loadSourcesSuccess, loadSourcesFailed
} = getReviews.actions;
export default getReviews.reducer;
