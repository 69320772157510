import { createSlice } from "@reduxjs/toolkit";
import {  singledayevents } from "../../../helpers/constants/reduxConstants";

const initialState = {
  onedaydata: [],
  isLoading: true,
  message: "",
};

const onedayEvents = createSlice({
  name: singledayevents,
  initialState,
  reducers: {
    loadsingledayEvents(state) {
      state.isLoading = true;
    },
    loadsingledayEventsSuccess(state, action) {
      state.onedaydata = action.data;
      state.isLoading = false;
    },
    loadsingledayEventsFailed(state) {
      state.message = "loading events failed";
      state.isLoading = false;
    },
  },
});
export const { loadsingledayEvents,loadsingledayEventsSuccess, loadsingledayEventsFailed  } = onedayEvents.actions;
export default onedayEvents.reducer;
