import { createSlice } from "@reduxjs/toolkit";
import { rates } from "../../../helpers/constants/reduxConstants";

const initialState = {
  data: [],
  isLoading: true,
  message: "",
  rangeData: null,
  navigate2Day: 0,
  dt: new Date(),
};

const ratesFilter = createSlice({
  name: rates,
  initialState,
  reducers: {
    loadTopbarFilterLookup(state) {
      state.isLoading = true;
      state.data = [];
      state.message = "";
    },
    loadRatesFilter(state) {
      state.isLoading = true;
    },
    loadRatesFilterSuccess(state, action) {
      state.data = action.data;
      state.isLoading = false;
    },
    loadRatesFilterFailed(state) {
      state.message = "loading rates filters failed";
      state.isLoading = false;
    },
  },
});
export const { loadRatesFilter, loadRatesFilterSuccess, loadRatesFilterFailed, loadTopbarFilterLookup } = ratesFilter.actions;
export default ratesFilter.reducer;
