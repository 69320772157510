import axios from "axios";
import _ from "underscore";

import {
  loadRatesCalender, loadRatesCalenderSuccess, loadRatesCalenderFailed,
  loadRatesGraph, loadRatesGraphSuccess, loadRatesGraphFailed,
  loadRatesToday, loadRatesTodaySuccess, loadRatesTodayFailed,
  loadRatesFilter, loadRatesFilterSuccess, loadRatesFilterFailed,
  loadRatesHoliday, loadRatesHolidaySuccess, loadRatesHolidayFailed,
  loadRatesDemandScore, loadRatesDemandScoreSuccess, loadRatesDemandScoreFailed,
  loadRatesTopFilter, loadRatesTopFilterSuccess, loadRatesTopFilterFailed, loadTopbarFilterLookup, loadRatesTopFilterLookup,loadRatesRefreshed,
  loadHotelDetails, loadHotelDetailsSuccess, loadHotelDetailsFailed,
  loadWeekDay, loadWeekDaySuccess, loadWeekDayFailed,
  loadRefreshCount, loadRefreshCountSuccess, loadRefreshCountFailed,
  loadAccounts, loadAccountsSuccess, loadAccountsFailed,
  loadEvents, loadEventsSuccess, loadEventsFailed,
  loadReviews, loadReviewsSuccess, loadReviewsFailed,
  loadHotelReviews, loadHotelReviewsSuccess, loadHotelReviewsFailed,
  loadSources, loadSourcesSuccess, loadSourcesFailed,
  loadEventsFilter, loadEventsFilterSuccess, loadEventsFilterFailed,
  loadGetInsights, loadGetInsightsSuccess, loadGetInsightsFailure,
  loadsingledayEvents, loadsingledayEventsSuccess, loadsingledayEventsFailed,
  loadAdmin, loadAdminSuccess, loadAdminFailed,
  loadUser, loadUserSuccess, loadUserFailed, loadUserDelete, loadUserDeleteSuccess,
  loadUserDisableSuccess, loadShopDelete, loadShopDeleteSuccess,
  loadShopDeleteFailed, loadUserDisable, loadUserDisableFailed, loadUserDeleteFailed,
  loadResetPassword, loadResetPasswordSuccess, loadResetPasswordFailed,
  loadOtaSource, loadOtaSourceSuccess, loadOtaSourceFailed, loadRatesCalenderLookup, loadRateParityLookup,
  loadManager, loadManagerSuccess, loadManagerFailed,
  loadRateParity, loadRateParitySuccess, loadRateParityFailed,
  loadAllEvents, loadAllEventsSuccess, loadAllEventsFailed,
  loadPromoCodes, loadPromoCodesFailed, loadPromoCodesSuccess,
  loadApplyPromocodes, loadApplyPromocodesSuccess, loadApplyPromocodesFailed, loadResetPromocodes,
  loadUpdateRequest, loadUpdateRequestSuccess, loadGetInsightsInitial, loadNewEmail, loadNewEmailSuccess, loadNewEmailFailed,
  loadApplySkipTour, loadApplySkipTourSuccess, loadApplySkipTourFailed, loadEventsClear,
  loadChannelsOtaSource, loadChannelsOtaSourceSuccess, loadChannelsOtaSourceFailed,loadChannelsOtaSourceAll, resetloadChannelsOtaSourceAll, loadChildDelete, loadChildDeleteSuccess, loadChildDeleteFailed
} from "../../helpers/constants/reduxConstants";
import { Completed, filterNoRecore, marketReviews } from "../../helpers/constants/variableConstants";
import { CONFIG } from "./config";
import { hoteldetails, hotelinformation } from "../../helpers/constants/routerConstants";

const APIServices = () => (next) => (action) => {
  const current = new Date();
  const time = current.toLocaleTimeString("en-US");
  // const user_details = JSON.parse(localStorage.getItem("userDetails")) ? JSON.parse(localStorage.getItem("userDetails")) : {};

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      next({
        type: "SERVER_ERROR",
        message: "server disconnected please check",
      });
      if (error && error.response && error.response.status === 401) {
        console.log(error);
      }
    }
  );
  switch (action.type) {
    case loadRatesCalender: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.data
      
      axios.post(`${CONFIG.MonolithicApi}rmapi/picasso`, data, config)
        .then((response) => {
          next({
            type: loadRatesCalenderSuccess,
            data: response,
          });
        })
        .catch((error) => {
          next({
            type: loadRatesCalenderFailed,
            message: error,
            status: 400
          });
          console.log("/rm ended at---", time)
        });
      break;
    }

    case loadRatesDemandScore: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data = {
        api: "events",
        endpoint: "/apidemantfetch",
        data: [action.payload.data]
      }
      axios.post(`${CONFIG.MonolithicApi}monolithic`, data, config)
        .then((response) => {
          next({
            type: loadRatesDemandScoreSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadRatesDemandScoreFailed,
            message: error,
          });
          console.log("/rm ended at---", time)
        });
      break;
    }
    case loadRateParity: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data =action.payload.data
      
      axios.post(`${CONFIG.MonolithicApi}rmapi/rateparity`, data, config)
        .then((response) => {
          next({
            type: loadRateParitySuccess,
            data: response.data && response.data.message === filterNoRecore ? [] : response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadRateParityFailed,
            message: error,
          });
        });
      break;
    }
    case loadRatesHoliday: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data = {
        api: "events",
        endpoint: "/holiday",
        data: [action.payload.data]
      }
      axios.post(`${CONFIG.MonolithicApi}monolithic`, data, config)
        .then((response) => {
          next({
            type: loadRatesHolidaySuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadRatesHolidayFailed,
            message: error,
          });
        });
      break;
    }


    case loadRatesTopFilter: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data =  action.payload.data;
      axios.post(`${CONFIG.MonolithicApi}rmapi/topbarfilter`, data, config)
        .then((response) => {
          next({
            type: loadRatesTopFilterSuccess,
            data: response,
          });
        })
        .catch((error) => {
          next({
            type: loadRatesTopFilterFailed,
            message: error,
            status: 400
          });
        });
      break;
    }

    case loadHotelDetails: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data = {
        api: "hotelsinfo",
        endpoint: hoteldetails,
        data: [action.payload.data]
      }
      axios.post(`${CONFIG.MonolithicApi}monolithic`, data, config)
        .then((response) => {
          next({
            type: loadHotelDetailsSuccess,
            data: response.data,
            hed: response.headers,
          });
        })
        .catch((error) => {
          next({
            type: loadHotelDetailsFailed,
            message: error,
          });
        });
      break;
    }

    case resetloadChannelsOtaSourceAll : {
      next({
        type: resetloadChannelsOtaSourceAll,
      })
      break;
    }

    case loadChannelsOtaSource: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data = {
        api: "hotelinfos",
        endpoint: hotelinformation,
        data: [action.payload.data]
      }
      next({
        type: resetloadChannelsOtaSourceAll,
      })
      next({
        type: loadChannelsOtaSource,
      })
      axios.post(`${CONFIG.MonolithicApi}monolithic`, data, config)
        .then((response) => {
          const sources = response?.data?.data?.flatMap((res) => res?.sources)
          let sourceList = [];
          sources?.forEach((res) => {
            if(!sourceList?.map((data)=> data?.websitecode)?.includes(parseInt(res?.websitecode))){
              sourceList?.push(res)
            } 
          })
          next({
            type: loadChannelsOtaSourceSuccess,
            data: sourceList,
            hed: response.headers,
          });
          next({
            type:loadChannelsOtaSourceAll,
            data:response?.data?.data
          });
        })
        .catch((error) => {
          next({
            type: loadChannelsOtaSourceFailed,
            message: error,
          });
        });
      break;
    }

    case loadRatesGraph: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data = {
        api: "rmdatafetch",
        endpoint: "/twoweekrates",
        data: [action.payload.data]
      }
      axios.post(`${CONFIG.MonolithicApi}monolithic`, data, config)
        .then((response) => {
          next({
            type: loadRatesGraphSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadRatesGraphFailed,
            message: error,
          });
        });
      break;
    }

    case loadRatesToday: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data = {
        api: "rmdatafetch",
        endpoint: "/todayrates",
        data: [action.payload.data]
      }
      axios.post(`${CONFIG.MonolithicApi}monolithic`, data, config)
        .then((response) => {
          next({
            type: loadRatesTodaySuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadRatesTodayFailed,
            message: error,
          });
        });
      break;
    }

    case loadRatesFilter: {
      let user_details = JSON.parse(localStorage.getItem("userDetails")) ? JSON.parse(localStorage.getItem("userDetails")) : {};
      const shop_id = !_.isEmpty(user_details) ? user_details["shopid"] : "";
      const user_id = !_.isEmpty(user_details) && user_details["userid"] ? user_details["userid"] : "";
      let req_params = { shopid: shop_id, userid: user_id, startdate: action.payload.date.startdate, enddate: action.payload.date.enddate };

      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data = {
        api: "rmdatafetch",
        endpoint: "/filterrates",
        data: [req_params]
      }
      axios.post(`${CONFIG.MonolithicApi}monolithic`, data, config)
        .then((response) => {
          next({
            type: loadRatesFilterSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadRatesFilterFailed,
            message: error,
          });
        });
      break;
    }

    case loadAccounts: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.data
      axios.post(`${CONFIG.MonolithicApi}myaccount`, data, config)
        .then((response) => {
          next({
            type: loadAccountsSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadAccountsFailed,
            message: error,
          });
        });
      break;
    }
    case loadResetPassword: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.data
      axios.post(`${CONFIG.MonolithicApi}auth/resetpassword`, data, config)
        .then((response) => {
          next({
            type: loadResetPasswordSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadResetPasswordFailed,
            message: error,
          });
        });
      break;
    }

    case loadWeekDay: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.data
      axios.post(`${CONFIG.MonolithicApi}shops/weekdaystart`, data, config)
        .then((response) => {
          next({
            type: loadWeekDaySuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadWeekDayFailed,
            message: error,
          });
        });
      break;
    }

    case loadRefreshCount: {
      const config = {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.data
      axios.post(`${CONFIG.MonolithicApi}shops/refreshcount`, data, config)
        .then((response) => {
          next({
            type: loadRefreshCountSuccess,
            data: response,
          });
        })
        .catch((error) => {
          next({
            type: loadRefreshCountFailed,
            message: error,
          });
        });
      break;
    }

    case loadEvents: {
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.data
      axios.post(`${CONFIG.MonolithicApi}monolithic`, data, config)
        .then((response) => {
          next({
            type: loadEventsSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadEventsFailed,
            message: error,
          });
        });
      break;
    }
    case loadAllEvents: {
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.data
      if(data.data[0].category!==""||data.data[0].category!==""||data.data[0].proximity !=="10"||data.data[0].estimatedattendees!==">0"){
        sessionStorage.setItem("evFiltered",true)
      }else{
        sessionStorage.setItem("evFiltered",false)
      }
      axios.post(`${CONFIG.MonolithicApi}monolithic`, data, config)
        .then((response) => {
          next({
            type: loadAllEventsSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadAllEventsFailed,
            message: error,
          })
        });
      break;
    }

    case loadsingledayEvents: {
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.data;
      axios.post(`${CONFIG.MonolithicApi}monolithic`, data, config)
        .then((response) => {
          next({
            type: loadsingledayEventsSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadsingledayEventsFailed,
            message: error,
          });
        });
      break;
    }
    case loadReviews: {
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.data
      axios.post(`${CONFIG.MonolithicApi}monolithic`, data, config)
        .then((response) => {
          next({
            type: loadReviewsSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadReviewsFailed,
            message: error,
          });
        });
      break;
    }
    case loadHotelReviews: {
      const config = {
        headers: {
          Authorization: action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = marketReviews
      axios.post("https://reviews.airfare-api.aggregateintelligence.com/entity/reviews/summary", data, config)
        .then((response) => {
          next({
            type: loadHotelReviewsSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadHotelReviewsFailed,
            message: error,
          });
        });
      break;
    }
    case loadSources: {
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",
          //  
        }
      }
      const data = JSON.stringify(action.payload.data)
      axios.post(`${CONFIG.MonolithicApi}monolithic`, data, config)
        .then((response) => {
          next({
            type: loadSourcesSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadSourcesFailed,
            message: error,
          });
        });
      break;
    }
    case loadEventsFilter: {
      let event_params = action.payload.data;
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = event_params
      axios.post(CONFIG.MonolithicApi + "events", data, config)
        .then((response) => {
          next({
            type: loadEventsFilterSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadEventsFilterFailed,
            message: error,
          });
        });
      break;
    }
    case loadGetInsights: {


      const config = {
        headers: {
          Authorization:`Bearer ${action.payload.token}`,
          "Content-Type": "application/json",
        }
      }
      const data = action.payload.data
      axios.post(`${CONFIG.MonolithicApi}monolithic`,data, config)
        .then((response) => {
          if(response.status === 200){
            localStorage.setItem("questatus",response?.data?.message === "Queues Completed!" ? Completed : "In-progress" )
          }
          next({
            type: loadGetInsightsSuccess,
            data: response,
            count: 1
          });
        })
        .catch((error) => {
          next({
            type: loadGetInsightsFailure,
            message: error,
            status: 400,
            count: 1
          });
        });
      break;
    }
    case loadAdmin: {
      axios
        .get(`${CONFIG.MonolithicApi}shops/admin`, {
          headers: {
            "Authorization": "Bearer " + action.payload.token,
            "Content-Type": "application/json",

          }
        })
        .then((res) => {
          next({
            type: loadRatesCalenderLookup
          })
          next({
            type: loadTopbarFilterLookup
          })
          next({
            type: loadRatesTopFilterLookup
          })
          next({
            type: loadRateParityLookup
          })
          next({
            type: loadAdminSuccess,
            data: res.data[0],
          });
        })
        .catch((err) => {
          next({
            type: loadAdminFailed,
            message: err,
          });
        });
      break;
    }
    case loadPromoCodes: {
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.data
      axios.post(CONFIG.MonolithicApi + "admin/promocode", data, config)
        .then((res) => {
          next({
            type: loadPromoCodesSuccess,
            data: res.data,
          });
        })
        .catch((err) => {
          next({
            type: loadPromoCodesFailed,
            message: err,
          });
        });
      break;
    }

    case loadApplyPromocodes: {
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.data
      axios.post(CONFIG.MonolithicApi + "admin/promocodevalid", data, config)
        .then((res) => {
          next({
            type: loadApplyPromocodesSuccess,
            data: res.data,
          });
        })
        .catch((err) => {
          next({
            type: loadApplyPromocodesFailed,
            message: err,
          });
        });
      break;
    }

    case loadResetPromocodes: {
      next({
        type: loadResetPromocodes,
      });
      break;
    }

    case loadUser: {
      axios
        .get(`${CONFIG.MonolithicApi}auth/getusers`, {
          headers: {
            "Authorization": "Bearer " + action.payload.token,
            "Content-Type": "application/json",

          }
        })
        .then((res) => {
          next({
            type: loadUserSuccess,
            data: res.data,
          });
        })
        .catch((err) => {
          next({
            type: loadUserFailed,
            message: err,
          });
        });
      break;
    }
    case loadOtaSource: {
      axios
        .get(`${CONFIG.MonolithicApi}shops/sources`, {
          headers: {
            "Authorization": "Bearer " + action.payload.token,
            "Content-Type": "application/json",

          }
        })
        .then((res) => {
          next({
            type: loadOtaSourceSuccess,
            data: res.data,
          });
        })
        .catch((err) => {
          next({
            type: loadOtaSourceFailed,
            message: err,
          });
        });
      break;
    }
    case loadUserDelete: {
      let event_params = action.payload.data;
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = event_params
      axios.post(CONFIG.MonolithicApi + "admin/delete/account", data, config)
        .then((response) => {
          next({
            type: loadUserDeleteSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadUserDeleteFailed,
            message: error,
          });
        });
      break;
    }
    case loadManager: {
      let manager_mail = action.payload.data;
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = manager_mail
      axios.post(CONFIG.MonolithicApi + "auth/getwhitelabelusers", data, config)
        .then((response) => {
          next({
            type: loadRatesCalenderLookup
          })
          next({
            type: loadTopbarFilterLookup
          })
          next({
            type: loadRatesTopFilterLookup
          })
          next({
            type: loadRateParityLookup
          })
          next({
            type: loadManagerSuccess,
            data: response.data,
          })
          next({
            type: loadEventsClear,

          });
        })
        .catch((error) => {
          next({
            type: loadManagerFailed,
            message: error,
          });
        });
      break;
    }
    case loadUpdateRequest: {
      next({
        type: loadRatesCalenderLookup
      })
      next({
        type: loadTopbarFilterLookup
      })
      next({
        type: loadRatesTopFilterLookup
      })
      next({
        type: loadRateParityLookup
      })
      next({
        type: loadUpdateRequestSuccess
      })
      next({
        type: loadGetInsightsInitial
      })
      break;
    }
    case loadGetInsightsInitial: {
      next({
        type: loadGetInsightsInitial
      })
      break
    }
    case loadRatesRefreshed: {
      next({
        type: loadRatesCalenderLookup
      })
      next({
        type: loadTopbarFilterLookup
      })
      next({
        type: loadRatesTopFilterLookup
      })
      break
    }
    case loadShopDelete: {
      let event_params = action.payload.data;
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = event_params
      axios.post(CONFIG.MonolithicApi + "admin/delete/rateshop", data, config)
        .then((response) => {
          next({
            type: loadShopDeleteSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadShopDeleteFailed,
            message: error,
          });
        });
      break;
    }
    case loadUserDisable: {
      let event_params = action.payload.data;
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = event_params
      axios.post(CONFIG.MonolithicApi + "admin/account/activation", data, config)
        .then((response) => {
          next({
            type: loadUserDisableSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadUserDisableFailed,
            message: error,
          });
        });
      break;
    }

    case loadChildDelete: {
      let event_params = action.payload.data;
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = event_params
      axios.post(CONFIG.MonolithicApi + "admin/account/managerdelete", data, config)
        .then((response) => {
          next({
            type: loadChildDeleteSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadChildDeleteFailed,
            message: error,
          });
        });
      break;
    }

    case loadNewEmail: {
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.datas
      axios.post(CONFIG.MonolithicApi + "shops/sendmail", data, config)
        .then((response) => {
          next({
            type: loadNewEmailSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadNewEmailFailed,
            message: error,
          });
        });
      break;
    }

    case loadApplySkipTour: {
      const config = {
        headers: {
          Authorization: "Bearer " + action.payload.token,
          "Content-Type": "application/json",

        }
      }
      const data = action.payload.data;
      axios.post(CONFIG.MonolithicApi + "myaccount/skiptourupdate", data, config)
        .then((response) => {
          next({
            type: loadApplySkipTourSuccess,
            data: response.data,
          });
        })
        .catch((error) => {
          next({
            type: loadApplySkipTourFailed,
            message: error,
          });
        });
      break;

    }
    default:
  }
};

export default APIServices;

