import { createSlice } from "@reduxjs/toolkit";
import { rates } from "../../../helpers/constants/reduxConstants";

const weekday_state = {
  data: [],
  isLoading: true,
  message: "",
};

const weekDay = createSlice({
  name: rates,
  initialState: weekday_state,  
  reducers: {
    loadWeekDay(state) {
      state.isLoading = true;      
    },
    loadWeekDaySuccess(state, action) {
      state.data = action.data;
      state.isLoading = false;
    },
    loadWeekDayFailed(state) {
      state.message = "loading weekday failed";
      state.isLoading = false;
    },
  },
});
export const { loadWeekDay, loadWeekDaySuccess, loadWeekDayFailed } = weekDay.actions;
export default weekDay.reducer;
