import React, { useState } from "react";
import Header from "../core/header/Header";
import Footer from "../core/footer/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";
import { managerUserEmails } from "../helpers/constants/variableConstants";

const DashboardRoute = ({ children }) => {
  const languageLabel = window.Weglot.getCurrentLang() ? window.Weglot.getCurrentLang() : localStorage.getItem("wglang");
  const languageName = window.Weglot.getCurrentLang() ? window.Weglot.getLanguageName(languageLabel) : "English";
  const managerEmailAddress = sessionStorage.getItem("manageremail") !== null ? sessionStorage.getItem("manageremail") : {};

  const location = useLocation();
  const { user, isAuthenticated } = useAuth0();
  const [isParentData, setIsParentData] = useState({ language: languageName });
  const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

  const history = useHistory();

  return (
    <div className="page-het">
      {(isAuthenticated && user.email_verified && userDetails.shopstatus && !userDetails?.isEventPage && (location?.pathname === "/single_event_page" || location?.pathname === "/single_day_events" || location?.pathname === "/events")) ? "" :
        <>
          {managerUserEmails?.includes(managerEmailAddress) && userDetails?.iswhiteLabel && window !== window.parent ? "" : <Header toChild={isParentData} sendToParent={setIsParentData} />}
          {children}
          <Footer toprops={isParentData} />
        </>
      }
    </div>
  );
};

export default DashboardRoute;
