import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { rates } from "../../../helpers/constants/reduxConstants";

const rates_state = {
  data: [],
  isLoading: true,
  message: "",
  monthName: null,
  dt: moment().utc(),
  demandScore: null,
  filterOptions: null,
};

const ratesCalender = createSlice({
  name: rates,
  initialState: rates_state,  
  reducers: {
    loadRatesCalenderLookup(state){
      state.data= [];
      state.isLoading= true;
      state.message= "";
      state.monthName= null;
      state.dt= moment().utc();
      state.demandScore= null;
      state.filterOptions= null;
    },
    loadRatesCalender(state) {
      state.isLoading = true;      
    },
    loadRatesCalenderSuccess(state, action) {
      state.data = action.data.data;
      state.statusCode = action.data && action.data.status ? action.data.status : "";
      state.isLoading = false;
    },
    loadRatesCalenderFailed(state, action) {
      state.message = "loading rates failed";
      state.statusCode = action.status ? action.status : "";
      state.isLoading = false;
    },
  },
});
export const { loadRatesCalender, loadRatesCalenderSuccess, loadRatesCalenderFailed, loadRatesCalenderLookup } = ratesCalender.actions;
export default ratesCalender.reducer;
