import { createSlice } from "@reduxjs/toolkit";
import { rates } from "../../../helpers/constants/reduxConstants";

const initialState = {
  data: [],
  isLoading: true,
  message: "",
};

const ratesHoliday = createSlice({
  name: rates,
  initialState,
  reducers: {
    loadRatesHoliday(state) {
      state.isLoading = true;
    },
    loadRatesHolidaySuccess(state, action) {
      state.data = action.data;
      state.isLoading = false;
    },
    loadRatesHolidayFailed(state) {
      state.message = "loading rates graph failed";
      state.isLoading = false;
    },
  },
});
export const { loadRatesHoliday, loadRatesHolidaySuccess, loadRatesHolidayFailed } = ratesHoliday.actions;
export default ratesHoliday.reducer;
