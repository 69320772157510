import { createSlice } from "@reduxjs/toolkit";
import { updateReq } from "../../../helpers/constants/reduxConstants";

const initialState = {
  data: [],
  isLoading: true,
  message: "",
  statusCode: 200
};

const updateRequest = createSlice({
  name: updateReq,
  initialState,
  reducers: {
    loadUpdateRequest(state) {
      state.isLoading = true;
    },
    loadUpdateRequestSuccess(state){

    }
}
});
export const { loadUpdateRequest,loadUpdateRequestSuccess } = updateRequest.actions;
export default updateRequest.reducer;
