import { createSlice } from "@reduxjs/toolkit";
import { events } from "../../../helpers/constants/reduxConstants";

const initialState = {
  data: {},
  isLoading: true,
  message: "",
  filterData:[],
  AllEventsData: {},
};

const getEvents = createSlice({
  name: events,
  initialState,
  reducers: {
    loadEvents(state) {
            state.isLoading = true;
    },
    loadEventsSuccess(state, action) {
      state.AllEventsData = action.data;
      state.isLoading = false;
    },
    loadEventsFailed(state) {
      state.message = "loading events failed";
      state.isLoading = false;
    },
    loadEventsClear(state){
      state.data= {};
      state.message= "";
      state.filterData=[];
      state.AllEventsData= {};
    },
    loadAllEvents(state) {
            state.isLoading = true;
    },
    loadAllEventsSuccess(state, action) {
            state.data = action.data;
      state.isLoading = false;
    },
    loadAllEventsFailed(state) {
      state.message = "loading events failed";
      state.isLoading = false;
    },
    loadEventsFilter(state) {
      state.isLoading = true;
    },
    loadEventsFilterSuccess(state, action) {
      state.filterData = action.data;
      state.isLoading = false;
    },
    loadEventsFilterFailed(state) {
      state.message = "loading events filters failed";
      state.isLoading = false;
    },

  },
});
export const { loadEvents, loadEventsSuccess, loadEventsFailed , loadEventsFilter, loadEventsFilterSuccess, loadEventsFilterFailed, loadAllEvents, loadAllEventsSuccess, loadAllEventsFailed, loadEventsClear } = getEvents.actions;
export default getEvents.reducer;
