import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import MasterLayout from "../layouts/masterLayout/masterLayout";
import DashboardLayout from "../layouts/dashboardLayout/dashboardLayout";
import {
  rates,
  events,
  reviews,
  rates_features,
  events_features,
  reviews_features,
  about,
  contact,
  competitors,
  ratesbyday,
  hoteldetails,
  single_event_page,
  welcome,
  payments,
  myaccount,
  single_day_events,
  user_type,
  viewShop,
  promoCodes,
  managerPanel,
  apiDataFeed,
  pricing,
  privacypolicy,
  termsandcondition,
  passwordReset,
  eventsSelect,
  matrics_login,
  compare,
  index,
  home,
  homepage,
  welcome_new
} from "../helpers/constants/routerConstants";
import Spinner from "../helpers/spinner/spinner";
import { useAuth0 } from "@auth0/auth0-react";
import { Container } from "reactstrap";

const LazyRates = lazy(() => lazyRetry(() => import("../components/rates/Rates")));
const LazyRatesByDay = lazy(() => lazyRetry(() => import("../components/rates/ratesByDay/RatesByDay")));
const LazyEvents = lazy(() => lazyRetry(() => import("../components/events/Events")));
const LazyReviews = lazy(() => lazyRetry(() => import("../components/reviews/Reviews")));
const LazyAbout = lazy(() => lazyRetry(() => import("../components/about/About")));
const LazyContact = lazy(() => lazyRetry(() => import("../components/contact/Contact")));
const LazyHome = lazy(() => lazyRetry(() => import("../components/home/Home")));
const LazyCompetitors = lazy(() => lazyRetry(() => import("../components/competitors/Competitors")));
const LazyHotelDetails = lazy(() => lazyRetry(() => import("../components/rates/ratesByDay/HotelDetails")));
const LazySingleEvents = lazy(() => lazyRetry(() => import("../components/events/eventsTable/SingleEvent")));
const LazyEventDetails = lazy(() => lazyRetry(() => import("../components/events/eventDetails/EventDetail")));
const LazyWelcome = lazy(() => lazyRetry(() => import("../components/../core/welcome/Welcome")));
const LazyPayments = lazy(() => lazyRetry(() => import("../components/home/payments/Payment")));
const LazyUserType = lazy(() => lazyRetry(() => import("../components/home/UserType")));
const LazyMyAccount = lazy(() => lazyRetry(() => import("../components/myAccount/MyAccount")));
const LazyRatesTemplate = lazy(() => lazyRetry(() => import("../templates/rates/ratesTemplate")));
const LazyEventsTemplate = lazy(() => lazyRetry(() => import("../templates/events/eventsTemplate")));
const LazyReviewsTemplate = lazy(() => lazyRetry(() => import("../templates/reviews/reviewsTemplate")));
const LazyViewShops = lazy(() => lazyRetry(() => import("../components/myAccount/adminView/AdminView")));
const LazyPromoCodes = lazy(() => lazyRetry(() => import("../components/myAccount/adminView/PromoCodes")));
const LazyManagerView = lazy(() => lazyRetry(() => import("../components/myAccount/managerView/ManagerView")));
const LazyPackages = lazy(() => lazyRetry(() => import("../components/pricing/pricing")));
const LazyPolicy = lazy(() => lazyRetry(() => import("../components/termsandcondition/privacypolicy/privacypolicy")));
const LazyTermsAndConditions = lazy(() => lazyRetry(() => import("../components/termsandcondition/termsandconditions/termsandconditions")));
const LazyResetPass = lazy(() => lazyRetry(() => import("../components/myAccount/ResetPassword")));
const LazyEventsCompetitor = lazy(() => lazyRetry(() => import("../components/competitors/eventsCompetitor/EventsCompetitor")));
const LazyApiDataFeed = lazy(() => lazyRetry(() => import("../components/home/apidatafeed/apidatafeed")));
const LazyAdminLog = lazy(() => lazyRetry(() => import("../components/myAccount/adminView/LoginUser")));
const LazyCompareGraph = lazy(() => lazyRetry(() => import("../components/compared/Compare")));
const LazyError = lazy(() => lazyRetry(() => import("../components/home/errorpage/error")));

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
        return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
};

const Routers = () => {
  const { user, isAuthenticated } = useAuth0();
  if (isAuthenticated) {
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) ? JSON.parse(localStorage.getItem("userDetails")) : {};
    if (!userDetails.email || userDetails.email === "") {
      userDetails["email"] = user.email;
      userDetails["name"] = user.nickname;
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
    }
  }
  return (
    <BrowserRouter base={index} className="no-translate">
      <Switch>
        <MasterLayout
          exact
          path={index}
          render={(matchprops) => (
            <Suspense fallback={<Container><Spinner /></Container>}>
              <LazyHome {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path={home}
          render={(matchprops) => (
            <Suspense fallback={<Container><Spinner /></Container>}>
              <LazyHome {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path={homepage}
          render={(matchprops) => (
            <Suspense fallback={<Container><Spinner /></Container>}>
              <LazyHome {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path={passwordReset}
          render={(matchprops) => (
            <Suspense fallback={<Container><Spinner /></Container>}>
              <LazyResetPass {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path={welcome}
          render={(matchprops) => (
            <Suspense fallback={<Container><Spinner /></Container>}>
              <LazyWelcome {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path={welcome_new}
          render={(matchprops) => (
            <Suspense fallback={<Container><Spinner /></Container>}>
              <LazyWelcome {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path={pricing}
          render={(matchprops) => (
            <Suspense fallback={<Container><Spinner /></Container>}>
              <LazyPackages {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path={matrics_login}
          render={(matchprops) => (
            <Suspense fallback={<Container><Spinner /></Container>}>
              <LazyAdminLog {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path={user_type}
          render={(matchprops) => (
            <Suspense fallback={<Container><Spinner /></Container>}>
              <LazyUserType {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path={competitors}
          render={(matchprops) => (
            <Suspense fallback={<Container><Spinner /></Container>}>
              <LazyCompetitors {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={rates}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyRates {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={rates_features}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyRatesTemplate {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={ratesbyday}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyRatesByDay {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={hoteldetails}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyHotelDetails {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={events}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyEvents {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={events_features}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyEventsTemplate {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={reviews}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyReviews {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={reviews_features}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyReviewsTemplate {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={compare}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyCompareGraph {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={about}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyAbout {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path={contact}
          render={(matchprops) => (
            <Suspense fallback={<Container><Spinner /></Container>}>
              <LazyContact {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={single_event_page}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazySingleEvents {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={single_day_events}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyEventDetails {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={myaccount}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyMyAccount {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={viewShop}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyViewShops {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={promoCodes}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyPromoCodes {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={managerPanel}
          render={(matchprops) => (
            <Suspense fallback={<Container className="auth-spinner"><Spinner /></Container>}>
              <LazyManagerView {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={apiDataFeed}
          render={(matchprops) => (
            <Suspense fallback={<Spinner />}>
              <LazyApiDataFeed {...matchprops} />
            </Suspense>
          )}
        />
        <DashboardLayout
          exact
          path={eventsSelect}
          render={(matchprops) => (
            <Suspense fallback={<div className="auth-spinner container"><Spinner /></div>}>
              <LazyEventsCompetitor {...matchprops} />
            </Suspense>
          )}
        />

        <MasterLayout
          exact
          path={payments}
          render={(matchprops) => (
            <Suspense fallback={<Container><Spinner /></Container>}>
              <LazyPayments {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path={privacypolicy}
          render={(matchprops) => (
            <Suspense fallback={<Spinner />}>
              <LazyPolicy {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path={termsandcondition}
          render={(matchprops) => (
            <Suspense fallback={<Spinner />}>
              <LazyTermsAndConditions {...matchprops} />
            </Suspense>
          )}
        />
        <MasterLayout
          exact
          path="*"
          render={(matchprops) => (
            <Suspense fallback={<Spinner />}>
              <LazyError {...matchprops} />
            </Suspense>
          )}
        />

      </Switch>
    </BrowserRouter>
  );
};

export default Routers;
