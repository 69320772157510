import { combineReducers } from "redux";
import ratesCalender from "./ratesCalender";
import ratesGraph from "./ratesGraph";
import ratesToday from "./ratesToday";
import ratesFilter from "./ratesFilter";
import ratesDemand from "./ratesDemand";
import ratesHoliday from "./ratesHoliday";
import ratesTopFilter from "./ratesTopFilter";
import hotelDetails from "./hotelDetails";
import weekDay from "./weekDay";
import refreshCount from "./refreshCount";
import ratesParity from "./rateParity";
import updateRequest from "./updateRequest";

const RatesReducer = combineReducers({ 
    ratesCalender, 
    ratesGraph, 
    ratesToday, 
    ratesFilter, 
    ratesDemand, 
    ratesHoliday, 
    ratesTopFilter, 
    hotelDetails, 
    weekDay,
    ratesParity, 
    refreshCount,
    updateRequest });

export default RatesReducer;
