/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./contactform.scss";

function Contactform() {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    script.rel="preconnect"
    script.defer = true;
    script.type = "text/javascript";
    script.async = true
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "6898561",
          css: "",
          target: ".Belch-kNgm489HRaFR7wneAZeh",
          formId: "b718e709-cd95-4f3e-b124-6a9cdd20d110"
        });
      }
    });
  }, []);

  return (
    <div>
      <div className="embed-form-super-wrapper Belch-kNgm489HRaFR7wneAZeh"></div>
    </div>
  );
}
export default Contactform;