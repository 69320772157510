import { createSlice } from "@reduxjs/toolkit";
import { promoCodes } from "../../../helpers/constants/reduxConstants";

const initialState = {
    data: [],
    isLoading: true,
    message: "",
}

const getPromocodes = createSlice({
    name: promoCodes,
    initialState,
    reducers: {
        loadPromoCodes(state) {
            state.isLoading = true;
        },
        loadPromoCodesSuccess(state, action) {
            state.data = action.data;
            state.isLoading = false;
        },
        loadPromoCodesFailed(state) {
            state.message = "loading accounts failed";
            state.isLoading = false;
        },
    }
});

export const {loadPromoCodes, loadPromoCodesSuccess, loadPromoCodesFailed } = getPromocodes.actions
export default getPromocodes.reducer;