import { createSlice } from "@reduxjs/toolkit";
import { rates } from "../../../helpers/constants/reduxConstants";

const rateparity_state = {
  data: [],
  isLoading: true,
  message: "",
  counter: 1
};

const ratesParity = createSlice({
  name: rates,
  initialState: rateparity_state,
  reducers: {

    loadRateParity(state) {
      state.isLoading = true;
    },
    loadRateParityLookup(state) {
      state.data = [];
      state.isLoading = true;
      state.message="";
      state.counter=0
    },
    loadRateParitySuccess(state, action) {
      state.data = action.data;
      state.isLoading = false;
      state.counter = state.counter + 1
    },
    loadRateParityFailed(state, action) {
      state.message = "loading rates failed";
      state.isLoading = false;
      state.data = []
    },
  },
});
export const { loadRateParity, loadRateParitySuccess, loadRateParityFailed, loadRateParityLookup } = ratesParity.actions;
export default ratesParity.reducer;
