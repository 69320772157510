import { createSlice } from "@reduxjs/toolkit";
import { rates } from "../../../helpers/constants/reduxConstants";

const initialState = {
  data: [],
  isLoading: true,
  message: "",
  rangeData: null,
  navigate2Day: 0,
  dt: new Date(),
};

const ratesToday = createSlice({
  name: rates,
  initialState,
  reducers: {
    loadRatesToday(state) {
      state.isLoading = true;
    },
    loadRatesTodaySuccess(state, action) {
      state.data = action.data;
      state.isLoading = false;
    },
    loadRatesTodayFailed(state) {
      state.message = "loading rates today failed";
      state.isLoading = false;
    },
  },
});
export const { loadRatesToday, loadRatesTodaySuccess, loadRatesTodayFailed } = ratesToday.actions;
export default ratesToday.reducer;
