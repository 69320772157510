import { createSlice } from "@reduxjs/toolkit";
import { accounts } from "../../../helpers/constants/reduxConstants";

const initialState = {
  data: [],
  isLoading: true,
  message: "",
  resetPass:""
};

const getAccounts = createSlice({
  name: accounts,
  initialState,
  reducers: {
    loadAccounts(state) {
      state.isLoading = true;
    },
    loadAccountsSuccess(state, action) {
      state.data = action.data;
      state.isLoading = false;
    },
    loadAccountsFailed(state) {
      state.message = "loading accounts failed";
      state.isLoading = false;
    },
    loadResetPassword(state) {
      state.isLoading = true;
    },
    loadResetPasswordSuccess(state, action) {
      state.resetPass = "Password Changed Successfully";
      state.isLoading = false;
    },
    loadResetPasswordFailed(state) {
      state.resetPass = "Password Change is failed";
      state.isLoading = false;
    }
    
  }
});
export const { loadAccounts, loadAccountsSuccess, loadAccountsFailed, loadResetPassword, loadResetPasswordSuccess, loadResetPasswordFailed } = getAccounts.actions;
export default getAccounts.reducer;
