import { createSlice } from "@reduxjs/toolkit";
import { manager } from "../../../helpers/constants/reduxConstants";

const initialState = {
    data: [],
    isLoading: true,
    message: "",
    count: 0,
};

const getManager = createSlice({
    name: manager,
    initialState,
    reducers: {
        loadManager(state) {
            state.isLoading = true;
        },
        loadManagerSuccess(state, action) {
            state.data = action.data;
            state.isLoading = false;
            state.count = state.count+1;
        },
        loadManagerFailed(state) {
            state.message = "loading accounts failed";
            state.isLoading = false;
            state.data=[];
            state.count = state.count+1;
        },
            }
});
export const { loadManager, loadManagerSuccess, loadManagerFailed } = getManager.actions;
export default getManager.reducer;
