import { createSlice } from "@reduxjs/toolkit";
import { rates } from "../../../helpers/constants/reduxConstants";

const initialState = {
  data: [],
  isLoading: true,
  message: "",
  monthName: null,
  dt: new Date(),
  filterOptions: null,
};

const ratesGraph = createSlice({
  name: rates,
  initialState,
  reducers: {
    loadRatesGraph(state) {
      state.isLoading = true;
    },
    loadRatesGraphSuccess(state, action) {
      state.data = action.data;
      state.isLoading = false;
    },
    loadRatesGraphFailed(state) {
      state.message = "loading rates graph failed";
      state.isLoading = false;
    },
  },
});
export const { loadRatesGraph, loadRatesGraphSuccess, loadRatesGraphFailed } = ratesGraph.actions;
export default ratesGraph.reducer;
