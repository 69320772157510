import { combineReducers } from "redux";
import RatesReducer from "./rates";
import EventsReducer from "./events";
import ReviewsReducer from "./reviews";
import AccountsReducer from "./accounts";
import InsightsReducer from "./getInsights";
import AdminReducer from "./adminPanel";
import CompReducers from "./competitors";
import SkipTourReducer from "./skiptour";

const rootReducer = combineReducers({
  Rates: RatesReducer,
  Events: EventsReducer,
  Reviews: ReviewsReducer,
  Accounts: AccountsReducer,
  Insight: InsightsReducer,
  Admin:AdminReducer,
  Competitor:CompReducers,
  SkipTour: SkipTourReducer,
});

export default rootReducer;
