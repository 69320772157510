//Rates
export const rates = "rates";
export const loadRatesCalender = "rates/loadRatesCalender";
export const loadRatesCalenderSuccess = "rates/loadRatesCalenderSuccess";
export const loadRatesCalenderFailed = "rates/loadRatesCalenderFailed";
export const loadRatesCalenderLookup = "rates/loadRatesCalenderLookup";
export const loadRatesRefreshed="rates/loadRatesRefreshed"

export const updateReq="updateReq";
export const loadUpdateRequest="updateReq/loadUpdateRequest";
export const loadUpdateRequestSuccess="updateReq/loadUpdateRequest/loadUpdateRequestSuccess";

export const loadRateParity = "rates/loadRateParity";
export const loadRateParityLookup = "rates/loadRateParityLookup";
export const loadRateParitySuccess = "rates/loadRateParitySuccess";
export const loadRateParityFailed = "rates/loadRateParityFailed";

export const loadRatesGraph = "rates/loadRatesGraph";
export const loadRatesGraphSuccess = "rates/loadRatesGraphSuccess";
export const loadRatesGraphFailed = "rates/loadRatesGraphFailed";

export const loadRatesDemandScore = "rates/loadRatesDemandScore";
export const loadRatesDemandScoreSuccess = "rates/loadRatesDemandScoreSuccess";
export const loadRatesDemandScoreFailed = "rates/loadRatesDemandScoreFailed";

export const loadRatesToday = "rates/loadRatesToday";
export const loadRatesTodaySuccess = "rates/loadRatesTodaySuccess";
export const loadRatesTodayFailed = "rates/loadRatesTodayFailed";

export const loadRatesFilter = "rates/loadRatesFilter";
export const loadRatesFilterSuccess = "rates/loadRatesFilterSuccess";
export const loadRatesFilterFailed = "rates/loadRatesFilterFailed";
export const loadTopbarFilterLookup = "rates/loadTopbarFilterLookup";

export const loadRatesTopFilter = "rates/loadRatesTopFilter";
export const loadRatesTopFilterSuccess = "rates/loadRatesTopFilterSuccess";
export const loadRatesTopFilterFailed = "rates/loadRatesTopFilterFailed";
export const loadRatesTopFilterLookup = "rates/loadRatesTopFilterLookup";

export const loadRatesHoliday = "rates/loadRatesHoliday";
export const loadRatesHolidaySuccess = "rates/loadRatesHolidaySuccess";
export const loadRatesHolidayFailed = "rates/loadRatesHolidayFailed";

export const loadHotelDetails = "rates/loadHotelDetails";
export const loadHotelDetailsSuccess = "rates/loadHotelDetailsSuccess";
export const loadHotelDetailsFailed = "rates/loadHotelDetailsFailed";

export const loadRefreshCount = "rates/loadRefreshCount";
export const loadRefreshCountSuccess = "rates/loadRefreshCountSuccess";
export const loadRefreshCountFailed = "rates/loadRefreshCountFailed";

export const loadWeekDay = "rates/loadWeekDay";
export const loadWeekDaySuccess = "rates/loadWeekDaySuccess";
export const loadWeekDayFailed = "rates/loadWeekDayFailed";

//Events
export const events = "events";
export const loadEvents = "events/loadEvents";
export const loadEventsSuccess = "events/loadEventsSuccess";
export const loadEventsFailed = "events/loadEventsFailed";
export const loadAllEvents = "events/loadAllEvents";
export const loadEventsClear = "events/loadEventsClear";
export const loadAllEventsSuccess = "events/loadAllEventsSuccess";
export const loadAllEventsFailed = "events/loadAllEventsFailed";
//singleday
export const singledayevents = "singledayevents";
export const loadsingledayEvents = "singledayevents/loadsingledayEvents";
export const loadsingledayEventsSuccess = "singledayevents/loadsingledayEventsSuccess";
export const loadsingledayEventsFailed = "singledayevents/loadsingledayEventsFailed";

export const loadEventsFilter = "events/loadEventsFilter";
export const loadEventsFilterSuccess = "events/loadEventsFilterSuccess";
export const loadEventsFilterFailed = "events/loadEventsFilterFailed";

//Reviews 
export const reviews = "reviews";
export const loadReviews = "reviews/loadReviews";
export const loadReviewsSuccess = "reviews/loadReviewsSuccess";
export const loadReviewsFailed = "reviews/loadReviewsFailed";
export const loadHotelReviews = "reviews/loadHotelReviews";
export const loadHotelReviewsSuccess = "reviews/loadHotelReviewsSuccess";
export const loadHotelReviewsFailed = "reviews/loadHotelReviewsFailed";
export const loadSources="reviews/loadSources"
export const loadSourcesSuccess="reviews/loadSourcesSuccess"
export const loadSourcesFailed="reviews/loadSourcesFailed"

//Account
export const accounts = "accounts";
export const loadAccounts = "accounts/loadAccounts";
export const loadAccountsSuccess = "accounts/loadAccountsSuccess";
export const loadAccountsFailed = "accounts/loadAccountsFailed";
export const loadResetPassword = "accounts/loadResetPassword";
export const loadResetPasswordSuccess = "accounts/loadResetPasswordSuccess";
export const loadResetPasswordFailed = "accounts/loadResetPasswordFailed";


export const getInsights ="getInsights";
export const loadGetInsights = "getInsights/loadGetInsights";
export const loadGetInsightsSuccess = "getInsights/loadGetInsightsSuccess";
export const loadGetInsightsFailure = "getInsights/loadGetInsightsFailure";
export const loadPaidGetInsights = "getInsights/loadPaidGetInsights";
export const loadPaidGetInsightsSuccess = "getInsights/loadPaidGetInsightsSuccess";
export const loadPaidGetInsightsFailure = "getInsights/loadPaidGetInsightsFailure";
export const loadGetInsightsInitial = "getInsights/loadGetInsightsInitial";

//manager
export const manager = "manager";
export const loadManager = "manager/loadManager";
export const loadManagerSuccess = "manager/loadManagerSuccess";
export const loadManagerFailed = "manager/loadManagerFailed";

// admin
export const admin = "admin";
export const loadAdmin = "admin/loadAdmin";
export const loadAdminSuccess = "admin/loadAdminSuccess";
export const loadAdminFailed = "admin/loadAdminFailed";
export const loadUser = "admin/loadUser";
export const loadUserSuccess = "admin/loadUserSuccess";
export const loadUserFailed = "admin/loadUserFailed";
export const loadUserDelete="admin/loadUserDelete";
export const loadUserDeleteSuccess="admin/loadUserDeleteSuccess";
export const loadUserDeleteFailed="admin/loadUserDeleteFailed";
export const loadShopDelete="admin/loadShopDelete";
export const  loadShopDeleteSuccess="admin/loadShopDeleteSuccess";
export const loadShopDeleteFailed="admin/loadShopDeleteFailed";
export const loadUserDisable="admin/loadUserDisable";
export const loadUserDisableSuccess="admin/loadUserDisableSuccess";
export const loadUserDisableFailed="admin/loadUserDisableFailed";
export const loadChildDelete="admin/loadChildDelete";
export const loadChildDeleteSuccess="admin/loadChildDeleteSuccess";
export const loadChildDeleteFailed="admin/loadChildDeleteFailed";

export const promoCodes = "promocodes";
export const loadPromoCodes = "promocodes/loadPromoCodes";
export const loadPromoCodesSuccess = "promocodes/loadPromoCodesSuccess";
export const loadPromoCodesFailed = "promocodes/loadPromoCodesFailed";

export const applyPromocodes = "applypromocodes";
export const loadApplyPromocodes = "applypromocodes/loadApplyPromocodes";
export const loadResetPromocodes = "applypromocodes/loadResetPromocodes";
export const loadApplyPromocodesSuccess = "applypromocodes/loadApplyPromocodesSuccess";
export const loadApplyPromocodesFailed = "applypromocodes/loadApplyPromocodesFailed";

//competitotors
export const competitors = "competitors";
export const loadOtaSource = "competitors/loadOtaSource";
export const loadOtaSourceSuccess = "competitors/loadOtaSourceSuccess";
export const loadOtaSourceFailed = "competitors/loadOtaSourceFailed";

//competitotors
export const channelsOta = "channels";
export const resetloadChannelsOtaSourceAll = "channels/resetloadChannelsOtaSourceAll";
export const loadChannelsOtaSource = "channels/loadChannelsOtaSource";
export const loadChannelsOtaSourceSuccess = "channels/loadChannelsOtaSourceSuccess";
export const loadChannelsOtaSourceAll = "channels/loadChannelsOtaSourceAll";
export const loadChannelsOtaSourceFailed = "channels/loadChannelsOtaSourceFailed";

// Unknown user mail notify
export const newuseremail="newuseremail";
export const loadNewEmail="newuseremail/loadNewEmail";
export const loadNewEmailSuccess="newuseremail/loadNewEmailSuccess";
export const loadNewEmailFailed="newuseremail/loadNewEmailFailed";

//SkipTour Status 

export const skipTour = "skiptour";
export const loadApplySkipTour  = "skiptour/loadApplySkipTour";
export const loadApplySkipTourSuccess  = "skiptour/loadApplySkipTourSuccess";
export const loadApplySkipTourFailed  = "skiptour/loadApplySkipTourFailed";
