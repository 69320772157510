import React from "react";
import Routers from "./router";
import "./app.scss";
// import axios from "axios";
// import CryptoJS from 'crypto-js';
// import { secretKey } from "../components/rates/RateHelper";

const App = () => {
    // const userDetails = useMemo(()=>(localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails")) : {}),[])
    // const encryptString = (plainText, secretKey) => {
    //     const encryptedText = CryptoJS.AES.encrypt(plainText, secretKey).toString();
    //     return encryptedText;
    // };

    // const FetchToken = useCallback(async()=>{
    //     try{
    //       const config = {
    //         headers:{
    //           'Accept-Encoding': 'text/html; charset=UTF-8',
    //         }
    //       }
    //       const { data } =await axios.get("https://rmapi-frontend-ai.s3.us-west-2.amazonaws.com/token.txt",config)
    //       const mapToken = encryptString(data,secretKey)
    //       const updatedData = {...userDetails,mapToken:mapToken} 
    //       localStorage.setItem("userDetails",JSON.stringify(updatedData))
    //     }catch(error){
    //       console.log(error)
    //     }
    //   },[userDetails])
      
    //   useEffect(()=>{
    //         FetchToken()
    //   },[FetchToken])
      
    return(<Routers />)
};

export default App;
