import { createSlice } from "@reduxjs/toolkit";
import { admin } from "../../../helpers/constants/reduxConstants";

const initialState = {
    data: [],
    isLoading: true,
    message: "",
    alluser: [],
    editadmin:"",
};

const getAdmin = createSlice({
    name: admin,
    initialState,
    reducers: {
        loadAdmin(state) {
            state.isLoading = true;
        },
        loadAdminSuccess(state, action) {
            state.data = action.data;
            state.isLoading = false;
        },
        loadAdminFailed(state) {
            state.message = "loading accounts failed";
            state.isLoading = false;
        },
        loadUser(state) {
            state.isLoading = true;
        },
        loadUserSuccess(state, action) {
            state.alluser = action.data;
            state.isLoading = false;
        },
        loadUserFailed(state) {
            state.message = "loading accounts failed";
            state.isLoading = false;
        },
        loadUserDelete(state) {
            state.isLoading = true;
        },
        loadUserDeleteSuccess(state, action) {
            state.editadmin = action.data;
            state.isLoading = false;
        },
        loadUserDeleteFailed(state) {
            state.message = "Deleting user failed";
            state.isLoading = false;
        },
        loadShopDelete(state) {
            state.isLoading = true;
        },
        loadShopDeleteSuccess(state, action) {
            state.editadmin = action.data;
            state.isLoading = false;
        },
        loadShopDeleteFailed(state) {
            state.message = "Deleting shop failed";
            state.isLoading = false;
        },
        loadUserDisable(state) {
            state.isLoading = true;
        },
        loadUserDisableSuccess(state, action) {
            state.editadmin = action.data;
            state.isLoading = false;
        },
        loadUserDisableFailed(state) {
            state.message = "Disabling user failed";
            state.isLoading = false;
        },
        loadChildDelete(state) {
            state.isLoading = true;
        },
        loadChildDeleteSuccess(state, action) {
            state.editadmin = action.data;
            state.isLoading = false;
        },
        loadChildDeleteFailed(state) {
            state.message = "Child user delete failed";
            state.isLoading = false;
        },

    }
});
export const { loadAdmin, loadAdminSuccess, loadAdminFailed, 
    loadUser, loadUserSuccess, loadUserFailed,
loadUserDelete,loadUserDeleteSuccess,loadUserDeleteFailed,
loadShopDelete, loadShopDeleteSuccess,loadShopDeleteFailed,
loadUserDisable,loadUserDisableSuccess,loadUserDisableFailed,
loadChildDelete, loadChildDeleteSuccess, loadChildDeleteFailed } = getAdmin.actions;
export default getAdmin.reducer;
