import { createSlice } from "@reduxjs/toolkit";
import { newuseremail } from "../../../helpers/constants/reduxConstants";

const initialState = {
    data: [],
    isLoading: true,
    message: "",
    count:0

};

const getMail = createSlice({
    name: newuseremail,
    initialState,
    reducers: {
        loadNewEmail(state) {
            state.isLoading = true;
        },
        loadNewEmailSuccess(state, action) {
            state.data = action.data;
            state.isLoading = false;
            state.count=state.count+1;
        },
        loadNewEmailFailed(state) {
            state.message = "loading accounts failed";
            state.isLoading = false;
        },
        

    }
});
export const { loadNewEmail,loadNewEmailSuccess, loadNewEmailFailed } = getMail.actions;
export default getMail.reducer;
