import { createSlice } from "@reduxjs/toolkit";
import { channelsOta } from "../../../helpers/constants/reduxConstants";

const initialState = {
    data: [],
    isLoading: null,
    message: "",
    allData:[]
};

const getChannels = createSlice({
    name: channelsOta,
    initialState,
    reducers: {
        resetloadChannelsOtaSourceAll(state) {
            state.allData = [];
        }, 
        loadChannelsOtaSource(state) {
            state.isLoading = true;
        },
        loadChannelsOtaSourceSuccess(state, action) {
            state.data = action.data;
            state.isLoading = false;
        },
        loadChannelsOtaSourceAll(state,action){
            state.allData = action.data;
        },
        loadChannelsOtaSourceFailed(state) {
            state.message = "loading accounts failed";
            state.isLoading = false;
        },
        

    }
});
export const { loadChannelsOtaSource,loadChannelOtaSourceSuccess, loadChannelOtaSourceFailed, loadChannelOtaSourceAll, resetloadChannelsOtaSourceAll } = getChannels.actions;
export default getChannels.reducer;
