import { createSlice } from "@reduxjs/toolkit";
import { rates } from "../../../helpers/constants/reduxConstants";

const initialState = {
  data: [],
  isLoading: true,
  message: "",
  statusCode: 200
};

const refreshCount = createSlice({
  name: rates,
  initialState,
  reducers: {
    loadRefreshCount(state) {
      state.isLoading = true;
    },
    loadRefreshCountSuccess(state, action) {
      state.data = action.data.data;
      state.isLoading = false;
      state.statusCode = action.data && action.data.status ? action.data.status : 400;
    },
    loadRefreshCountFailed(state, action) {
      state.message = "loading refresh count failed";
      state.isLoading = false;
      state.statusCode = action.status ? action.status : 400;
    },
  },
});
export const { loadRefreshCount, loadRefreshCountSuccess, loadRefreshCountFailed } = refreshCount.actions;
export default refreshCount.reducer;
