import React from "react";
import { Row, Col, Button, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ReactTooltip from 'react-tooltip';
import Contactform from "../../components/hubspotforms/contactform/contactform";
import "./footer.scss";
import _ from "underscore";
import pdfeng from "../../assets/PDF/Partnership Programme_ RM.pdf";
import pdfspanish from "../../assets/PDF/Partnership Programme RM Spanish.pdf";
import { CONFIG } from "../../redux/services/config";
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { EventsString, faFacebookF, faLinkedinIn } from "../../helpers/constants/variableConstants";

function Footer(props) {
  const { isAuthenticated } = useAuth0();
  const user_details = JSON.parse(localStorage.getItem("userDetails")) ? JSON.parse(localStorage.getItem("userDetails")) : {};
  const iswhiteLabel = !_.isEmpty(user_details) && user_details["iswhiteLabel"] ? user_details["iswhiteLabel"] : false;
  const languageWeglot = props.toprops ? props.toprops.language : "";
  const blogurl = `${CONFIG.UiUrl}blogs`;
  const history = useHistory();
  const handleClick = (type) => {
    history.push(`/${type}`);
  }

  const newYear = new Date().getFullYear()

  return (
    <div className="footer-bottom">
      <>
        {!iswhiteLabel ?
          <footer >
            <div className="footer-content">
              <Container className="footer-video-form">
                <Row>
                  <Col md={12}>
                    {isAuthenticated !== true ?
                      <div className="contact-form contact-form-rates " >
                        <h6>Contact US</h6>
                        <Contactform />
                      </div>
                      : ""}
                  </Col>
                </Row>

                <div className="footer-section">
                  <div className="footer-menu">
                    {user_details.plan_type !== EventsString ?
                      <>
                        <Button onClick={() => handleClick("rates")}>
                          <span>Rates</span>
                        </Button>
                        <Button onClick={() => handleClick("reviews")}>
                          <span>Reviews</span>
                        </Button>
                      </>
                      : ""}
                    <Button onClick={() => handleClick("events")}>
                      <span>Events</span>
                      <ReactTooltip place="bottom" className="menu-tooltip" />
                    </Button>
                    <Button onClick={() => handleClick("api-data-feed")}>
                      API
                    </Button>
                    <Button onClick={() => handleClick("pricing")}>
                      Pricing
                    </Button>
                    <Button onClick={() => handleClick("about")}>
                      About
                    </Button>
                    <Button onClick={() => handleClick("contact")}>
                      Contact
                    </Button>
                    {languageWeglot === "Español" ?
                      <a href={pdfspanish} target="_blank" rel="noreferrer" alt="Associations" className="footer-link">Associations</a>
                      :
                      <a href={pdfeng} target="_blank" rel="noreferrer" alt="Associations" className="footer-link">Associations</a>
                    }
                    {/* <a href={blogurl} target="_blank" rel="noreferrer" alt="Blog">
                      Blog
                    </a> */}
                    {/* <div><Button onClick={() => handleClick(index)}>Log in</Button></div> */}
                  </div>
                  <ul>
                    <li><Button onClick={() => handleClick("privacy-policy")}>Privacy Policy</Button></li>
                    <li>|</li>
                    <li><Button onClick={() => handleClick("terms-and-conditions")}>Terms of Service</Button></li>
                  </ul>
                </div>

                <div className="s-links-footer">
                  <span>
                    <a href="https://www.facebook.com/Ratemetrics" aria-label="Facebook" target="_blank" rel="noreferrer" alt="Facebook" title="Facebook">
                      {faFacebookF}
                    </a>
                  </span>
                  <span>
                    <a href="https://twitter.com/Ratemetrics" aria-label="Twitter" target="_blank" rel="noreferrer" alt="Twitter" title="Twitter">
                      <TwitterIcon rel="icon" />
                    </a>
                  </span>
                  <span>
                    <a href="https://www.instagram.com/ratemetrics" aria-label="Instagram" target="_blank" rel="noreferrer" alt="Instagram" title="Instagram">
                      <InstagramIcon rel="icon" />
                    </a>
                  </span>
                  <span>
                    <a href="https://www.linkedin.com/showcase/ratemetrics" aria-label="LinkedIn" target="_blank" rel="noreferrer" alt="LinkedIn" title="LinkedIn">
                      {faLinkedinIn}
                    </a>
                  </span>
                </div>
                <div className="copy-rights">
                  <p>© Copyright {newYear} Ratemetrics - All Rights Reserved</p>
                </div>
              </Container>
            </div>
          </footer>
          : ""
        }
      </>

    </div>
  );
}

export default Footer;
