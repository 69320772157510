import { createSlice } from "@reduxjs/toolkit";
import { rates } from "../../../helpers/constants/reduxConstants";

const initialState = {
  data: [],
  isLoading: true,
  message: "",
};

const hotelDetails = createSlice({
  name: rates,
  initialState,
  reducers: {
    loadHotelDetails(state) {
      state.isLoading = true;
    },
    loadHotelDetailsSuccess(state, action) {
      state.data = action.data;
      state.isLoading = false;
    },
    loadHotelDetailsFailed(state) {
      state.message = "loading hotel details failed";
      state.isLoading = false;
    },
  },
});
export const { loadHotelDetails, loadHotelDetailsSuccess, loadHotelDetailsFailed } = hotelDetails.actions;
export default hotelDetails.reducer;
