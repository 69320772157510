import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import APIServices from "../services/apiServices";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, APIServices],
  devTools: true,
  preloadedState: {},
});

export { store };
