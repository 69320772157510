import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app/app';
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { store } from "./redux/storeConfig/store";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "../src/helpers/utils/history";
import { getConfig } from "./config";

const root = ReactDOM.createRoot(document.getElementById('root'));

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};
// window.onbeforeunload = function (e) {
//   window.onunload = function () {
//     window.localStorage.isMySessionActive = "false";
//   }
//   return undefined;
// };

// window.onload = function () {
//           window.localStorage.isMySessionActive = "true";
// };
root.render(
  <Auth0Provider {...providerConfig}
    useRefreshTokens={true}
    cacheLocation="localstorage">
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>
);
