import React from "react";
// import { ClipLoader } from "react-spinners";
import "./spinner.scss";
import wave_gif from "../../assets/Images/wave-gir-loader.gif";
import { useAuth0 } from "@auth0/auth0-react";

const SpinnerComponent = ({styleClass}) => {
  const { isAuthenticated } = useAuth0()
  const userDetails = JSON.parse(localStorage.getItem("userDetails")) ? JSON.parse(localStorage.getItem("userDetails")) : {};

  return (

    <div className={`sweet-loading ${styleClass}`}>
      {window.parent !== window && (userDetails.iswhiteLabel || !isAuthenticated) ?
        <>
          <div className="lds-dual-ring"></div>

        </>
        :
        <div className="img-wd">
          <img src={wave_gif} className="wave-gif" as="image" alt="loader waves" width="114" height="43" loading="lazy" />
        </div>
      }

      {/* <span className="loader"></span> */}
      {/* <ClipLoader
        className="clip-loader background-w"
        sizeUnit={"px"}
        size={90}
        color={"#FF586B"}
        loading={loading}
      /> */}
    </div>
  );
};

export default SpinnerComponent;
