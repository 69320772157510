import { createSlice } from "@reduxjs/toolkit";
import { applyPromocodes } from "../../../helpers/constants/reduxConstants";
import { Invalid, Valid } from "../../../helpers/constants/variableConstants";

const initialState = {
    data: [],
    isLoading: false,
    message: "",
    count: 0
}

const getApplyPromocodes = createSlice({
    name: applyPromocodes,
    initialState,
    reducers: {
        loadApplyPromocodes(state) {
            state.isLoading = true;
        },
        loadResetPromocodes(state) {
            state.data = [];
            state.message = ""
            state.isLoading = false;
            state.count = 0;
        },
        loadApplyPromocodesSuccess(state, action) {
            state.data = action.data;
            state.message = Valid
            state.isLoading = false;
            state.count = state.count + 1;
        },
        loadApplyPromocodesFailed(state) {
            state.data = [];
            state.message = Invalid;
            state.isLoading = false;
            state.count = state.count + 1;
        },
    }
});

export const { loadApplyPromocodes, loadResetPromocodes, loadApplyPromocodesSuccess, loadApplyPromocodesFailed } = getApplyPromocodes.actions
export default getApplyPromocodes.reducer;