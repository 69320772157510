import { createSlice } from "@reduxjs/toolkit";
import { getInsights } from "../../../helpers/constants/reduxConstants";
import { Completed } from "../../../helpers/constants/variableConstants";

const initialState = {
  data: "In-Progress",
  isLoading: true,
  message: "",
  counter:0,
};

const getInsight = createSlice({
  name: getInsights,
  initialState,
  reducers: {
    loadGetInsights(state) {
      state.isLoading = true;
    },
    loadGetInsightsSuccess(state, action) {
      state.data = action.data.data.message==="Queues Completed!"?Completed:"In-Progress";
      state.isLoading = false;
      state.counter = state.counter +action.count;
    },
    loadGetInsightsFailure(state, action) {
      state.message = "loading insight failed";
      state.isLoading = false;
      state.counter = state.counter +action.count;
    },
    loadGetInsightsInitial(state){
      state.data = "Pending";
      state.isLoading = false;
      state.counter = 0;
      state.message="";
    }
  },
});
export const {loadGetInsights, loadGetInsightsSuccess, loadGetInsightsFailure,loadGetInsightsInitial  } = getInsight.actions;
export default getInsight.reducer;
