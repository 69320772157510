
export const index = "/";
export const home = "/home";
export const about = "/about";
export const rates = "/rates";
export const events = "/events";
export const contact = "/contact";
export const reviews = "/reviews";
export const welcome = "/welcome";
export const pricing = "/pricing";
export const homepage = "/homepage";
export const payments = "/payments";
export const viewShop = "/viewshop";
export const compare = "/comparision";
export const myaccount = "/myaccount";
export const user_type = "/authorize";
export const ratesbyday = "/ratesbyday";
export const promoCodes = "/promocodes";
export const competitors = "/competitors";
export const welcome_new = "/welcome_new";
export const apiDataFeed = "/api-data-feed";
export const hoteldetails = "/hoteldetails";
export const hotelinformation = "/hotelinformation";
export const managerPanel = "/managerpanel";
export const eventsSelect = "/events_select";
export const passwordReset = "/passwordreset";
export const matrics_login = "/matrics_login";
export const privacypolicy = "/privacy-policy";
export const rates_features = "/rates_features";
export const events_features = "/events_features";
export const reviews_features = "/reviews_features";
export const single_event_page = "/single_event_page";
export const single_day_events = "/single_day_events";
export const termsandcondition = "/terms-and-conditions";