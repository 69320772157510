import { createSlice } from "@reduxjs/toolkit";
import { competitors } from "../../../helpers/constants/reduxConstants";

const initialState = {
    data: [],
    isLoading: true,
    message: "",

};

const getComp = createSlice({
    name: competitors,
    initialState,
    reducers: {
        loadOtaSource(state) {
            state.isLoading = true;
        },
        loadOtaSourceSuccess(state, action) {
            state.data = action.data;
            state.isLoading = false;
        },
        loadOtaSourceFailed(state) {
            state.message = "loading accounts failed";
            state.isLoading = false;
        },
        

    }
});
export const { loadOtaSource,loadOtaSourceSuccess, loadOtaSourceFailed } = getComp.actions;
export default getComp.reducer;
