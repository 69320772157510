import { createSlice } from "@reduxjs/toolkit";
import { rates } from "../../../helpers/constants/reduxConstants";

const initialState = {
  demandScore: null,
  isLoading: true
};

const ratesDemand = createSlice({
  name: rates,
  initialState,
  reducers: {
    loadRatesDemandScore(state){
      state.isLoading = true;
    },
    loadRatesDemandScoreSuccess(state, action){
      state.demandScore = action.data;
      state.isLoading = false;
    },
    loadRatesDemandScoreFailed(state){
      state.isLoading = true;
    }
  },
});
export const { loadRatesDemandScore, loadRatesDemandScoreSuccess, loadRatesDemandScoreFailed } = ratesDemand.actions;
export default ratesDemand.reducer;
